import axios from 'axios';
import {unzlibSync, strFromU8, strToU8} from 'fflate';
import {
  API_BASE,
  API_KEY,
  API_URL,
  FINISH_CONFIGURATION_URL,
  FINISH_CONFIGURATION_URL_HQ,
} from '../config';

export function saveAccessToken(token) {
  if (typeof window !== 'undefined') {
    if (window.localStorage != null) {
      window.localStorage.setItem('accessToken', token);
    } else {
      // @ts-ignore
      window._access_token = token;
    }
  }
}

export function clearAccessToken() {
  if (typeof window !== 'undefined') {
    if (typeof window !== 'undefined' && window.localStorage != null) {
      window.localStorage.removeItem('accessToken');
    }
    // @ts-ignore
    delete window._access_token;
  }
}

export function getAccessToken() {
  if (typeof window !== 'undefined') {
    if (typeof window !== 'undefined' && window.localStorage != null) {
      return window.localStorage.getItem('accessToken');
    }
    // @ts-ignore
    return window._access_token;
  }
}

export function hasAccessToken() {
  if (typeof window !== 'undefined') {
    if (typeof window !== 'undefined' && window.localStorage != null) {
      return window.localStorage.getItem('accessToken') != null;
    }
    // @ts-ignore
    return window._access_token != null;
  }
}

export default class Universal20Service {
  constructor(controller = null) {
    this.controller = controller;
  }

  getHeader() {
    var headers = {
      Authorization: 'Bearer ' + getAccessToken(),
    };
    return headers;
  }

  getOptions() {
    return {
      headers: this.getHeader(),
    };
  }

  static getHostnameSubdomain() {
    const h = window.location.hostname.split('.');

    if (h.length > 1) {
      return h[0];
    }

    return '';
  }

  getBasicUrl() {
    const config_data_id = this.getCurrentHashParameter('cid');
    return (
      API_URL +
      API_BASE +
      '/' +
      Universal20Service.getHostnameSubdomain() +
      '/' +
      config_data_id
    );
  }

  getUrl(devMode = false) {
    return this.getBasicUrl() + '?devMode=' + devMode;
  }

  getModelSchema() {
    return this.getBasicUrl() + '/deepmodel';
  }

  getCurrentHashParameter(hashParameterName) {
    let theURL = new URL(window.location.origin); // create dummy url
    theURL.search = window.location.hash.substring(1);

    return theURL.searchParams.get(hashParameterName);
  }

  getRedirectUrl(reloadDataAfterLogin, callback_url, hash) {
    const states = {
      hash: hash,
      host: callback_url,
    };
    if (reloadDataAfterLogin) {
      states.reloadDataAfterLogin = reloadDataAfterLogin;
    }
    let callback_url_for_redirect =
      window.location.protocol + '//' + window.location.host;
    if (window.location.hostname.includes('localhost')) {
      callback_url_for_redirect = 'http://localhost:8080';
    }
    const state = JSON.stringify(states);
    const audience = 'u20';
    return (
      'https://agenturid.eu.auth0.com/authorize?state=' +
      encodeURIComponent(state) +
      '&response_type=token&scope=https://agenturid.com user_metadata app_metadata openid profile email&client_id=Sv08pnUlLUulbwkSHMZdRknclG7YX3dC&redirect_uri=' +
      callback_url_for_redirect +
      '&audience=' +
      audience
    );
  }

  redirectToLogin(reloadDataAfterLogin = false) {
    /*
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.replace(/#/g,"?"));
    const error = urlParams.get("error");
    if (error) {

    }
    else {
      const url = this.getRedirectUrl(reloadDataAfterLogin, window.location.host, window.location.hash);
      window.location = url;
    }*/
    if (this.controller) {
      this.controller.saveBeforeRedirectAndRedirect();
    }
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      try {
        return axios
          .post(url, data, this.getOptions())
          .then((d) => {
            return resolve(d);
          })
          .catch((err) => {
            if (err.response && err.response.status == 401) {
              this.redirectToLogin();
            }
            if (err.response && err.response.status == 403) {
              reject(err);
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  subscribe(url, infoCallbackMsg) {
    return new Promise((resolve, reject) => {
      this.latestReceivedIndex = 0;
      try {
        return axios({
          method: "GET",
          url: url,
          headers: this.getOptions().headers,
          responseType: "stream",
          onDownloadProgress: progressEvent => {
            try {
              const regex = /data: (.*)\n\n/gm;
              const xhr = progressEvent.srcElement.response;
              // Data comes in the form of:
              // data: {JSON}\n\n
              // Lets extract {JSON}


              let m;
              while ((m = regex.exec(xhr)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                  regex.lastIndex++;
                }

                if (m && m.length > 0) {
                  const sub_xhr = strFromU8(unzlibSync(strToU8(atob(m[m.length-1]), true)));

                  const jsonData = JSON.parse(sub_xhr);
                  infoCallbackMsg.onMessageReceived(jsonData);
                  if (jsonData.finished) {
                    resolve({ data: jsonData.data });
                  }
                }
              }

            }
            catch(e) {
              console.error(e);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status == 401) {
            this.redirectToLogin();
          }
          else if (err.response && err.response.status == 403) {
            reject(err);
          }
          else if (err) {
            reject(err);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  get(url, options) {
    return new Promise((resolve, reject) => {
      try {
        return axios
          .get(url, this.getOptions())
          .then((d) => {
            return resolve(d);
          })
          .catch((err) => {
            if (err.response && err.response.status == 401) {
              this.redirectToLogin();
            }
            if (err.response && err.response.status == 403) {
              reject(err);
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  loadDeepSchemaModel() {
    return this.get(this.getModelSchema()).then((response) => {
      return response.data.schema;
    });
  }

  loadConfigurationSide(devMode, infoCallbackMsg) {
    return this.subscribe(this.getUrl(devMode), infoCallbackMsg).then((response) => {
      return response.data;
    });
  }

  createOrder(questionController) {
    return new Promise((resolve, reject) => {
      try {
        return questionController
          .saveQuestionsComplete()
          .then(() => {
            const id = questionController.getId();
            const configurator_id = Universal20Service.getHostnameSubdomain();
            let posturl =
              API_URL +
              FINISH_CONFIGURATION_URL_HQ +
              '/' +
              configurator_id +
              '/' +
              id;
            return axios
              .post(posturl, {
                additionalProducts: questionController.getAdditionalProducts(),
              }, this.getOptions())
              .then((d) => {
                if (d.data) {
                  questionController.setFinished(true);
                }
                return resolve();
              })
              .catch((err) => {
                window.progressing = false;
                return reject({ message: err.response.data.message });
              });
          })
          .catch((err) => {
            window.progressing = false;
            return reject(err);
          });
      } catch (e) {
        window.progressing = false;
        reject(e);
      }
    });
  }

  setController(cb) {
    this.controller = cb;
  }

  getProductInfo(id) {
    return this.get(API_URL +"/api/configurators/hq/product/"+id).then((response) => {
      return response.data;
    });
  }
}
