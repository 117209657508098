import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import ReactQuill from "react-quill";

class QuestionWYSIWYG extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      text: null
    };
  }

  componentDidMount() {
    if (this.state.text === null) {
      this.state.text = this.getTextValue();
      this.setState({text: this.state.text});
    }
  }

  getTextValue() {
    let txt = this.getValue();
    if (Array.isArray(txt) && txt.length > 0) {
      txt = txt[0];
    }
    if (txt == null && this.hasVorauswahl()) {
      txt = this.getVorauswahl();
    }
    return txt;
  }

  onEditorChange(text) {
    this.setState({text: text});
    clearTimeout(this.fireUpdate);
    this.fireUpdate = setTimeout(() => this.setValue(this.state.text), 1500);
  }

  render() {
    return <Container fluid={true} className={"rich-text-container "+(this.hasError() ? "error" : "")}>
      <Row>
        <Col className={"p-1 wysiwyg-editor"} md={12}>
          {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
          <ReactQuill id={"quill-wysiwyg"} theme="snow" placeholder={this.placeholder()}
                      value={this.state.text}
                      readOnly={this.isFinished() || this.isDisabled()}
                      onChange={(text, delta, source, editor) => {
                          if (source == 'user') {
                            // place whatever function you want to execute when user types here:
                            this.onEditorChange(text);
                          }
                        }}
          />
          {this.renderErrors()}
        </Col>
      </Row>
    </Container>;
  }
};

QuestionWYSIWYG.propTypes = {

};


export default QuestionWYSIWYG;
